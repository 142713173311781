import { BillableEntity } from './billable-entity';
import { OrderItem } from './order-item';
import { MonetaryAmount } from './monetary-amount';
import { OrderLog } from './order-log';
import { Customer } from './customer';
import { OrderPayment } from './order-payment';
import { Fulfilment } from './fulfilment';
import { TableItemLabeledValue } from './table-item-labeled-value';
import { Invoice } from './invoice';

export interface OrderJson {
  submitedDate: Date;
  id: number;
  status: String;
  submitDate: Date;
  email: String;
  totalItems: number;
  billingAddress: any;
  discountApplied: boolean;
  fulfilments: Fulfilment[];
  customer: Customer;
  subTotal: MonetaryAmount;
  total: MonetaryAmount;
  totalTaxOrderItem: MonetaryAmount;
  items: OrderItem[];
  orderLogs: OrderLog[];
  adminNotes: string;
  orderPayments: OrderPayment[];
}

export class Order extends BillableEntity<OrderItem> {
  public email: String;
  public totalItems: number;
  public billingAddress: any;
  public discountApplied: boolean;
  public customer: Customer;
  public totalTaxOrderItem: MonetaryAmount;
  public orderLogs: OrderLog[];
  public adminNotes: string;
  public invoices: Invoice[];
  public orderPayments: OrderPayment[];
  public invoicedInFull: boolean;

  public static fromJson(json: OrderJson): Order {
    const order = Object.create(Order.prototype);
    return Object.assign(order, json);
  }

  /**
   * Remove this in favor of non static
   */
  public static emptyOrder(): Order {
    const order = Object.create(Order.prototype);

    const emptyOrder = {
      total: {
        amount: 0,
        currency: 'RSD'
      },
      totalItems: 0,
      currency: {
        currencyCode: 'RSD',
        friendlyName: 'Dinar'
      }
    };
    return Object.assign(order, emptyOrder);
  }

  public getItemValueByProperty(propertyName: string): TableItemLabeledValue {
    return super.getItemValueByProperty(propertyName);

  }

  public getItemClassByProperty(propertyName: string): string {
    return super.getItemClassByProperty(propertyName);
  }
}
