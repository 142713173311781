import { Status } from './status';
import { MonetaryAmount } from './monetary-amount';
import { TaxRate } from './tax-rate';
import { Currency } from './currency';
import { BillableEntityItem } from './billable-entity-item';
import { FieldState } from './field-state';
import { Warehouse } from './warehouse';
import { TableItemLabeledValue } from './table-item-labeled-value';
import { Fulfilment } from './fulfilment';
import { WithStatusEntity } from './with-status-entity';

export abstract class BillableEntity<I extends BillableEntityItem> extends WithStatusEntity {
        public createdBy: string;
        public createdDate: Date;
        public modifiedBy: string;
        public modifiedDate: Date;
        public submitDate: Date;
        public deliveryDate: Date;
        public priceWithoutTax: MonetaryAmount;
        public subTotal: MonetaryAmount;
        public totalTax: MonetaryAmount;
        public taxRate: TaxRate;
        public taxPerRate: MonetaryAmount[];
        public total: MonetaryAmount;
        public currency: Currency;
        public items: I[];
        public fulfilments: Fulfilment[];
        public fieldStates: FieldState[];
        public warehouse: Warehouse;
        public destinationWarehouse: Warehouse;
        public shippedInFull: boolean;
        public parentId: number;
        public version: number;
        public statusTransitionInProgress: boolean;

        public getItemPrefix(propertyName: string): string {
                if (this.hasOwnProperty(propertyName)) {
                        switch (propertyName) {
                                case 'priceWithoutTax': return this.priceWithoutTax.currency;
                                case 'totalTax': return this.totalTax.currency;
                                case 'subTotal': return this.subTotal.currency;
                                case 'total': return this.total.currency;
                        }
                }
        }
        public getItemValueByProperty(propertyName: string): TableItemLabeledValue {
                if (this.hasOwnProperty(propertyName)) {
                        switch (propertyName) {
                                case 'status':
                                        return TableItemLabeledValue.of(this.status ? this.status.name.replace(/_/g, ' ') : '');
                                case 'priceWithoutTax':
                                        if (this.priceWithoutTax) {
                                                return TableItemLabeledValue.of(this.priceWithoutTax);
                                        }
                                        break;
                                case 'taxRate':
                                        if (this.taxRate) {
                                                return TableItemLabeledValue.of(this.taxRate.rate.toFixed(2) + ' %');
                                        }
                                        break;
                                case 'totalTax':
                                        if (this.totalTax) {
                                                return TableItemLabeledValue.of(this.totalTax);
                                        }
                                        break;
                                case 'subTotal':
                                        if (this.subTotal) {
                                                return TableItemLabeledValue.of(this.subTotal);
                                        }
                                        break;
                                case 'total':
                                        if (this.total) {
                                                return TableItemLabeledValue.of(this.total);
                                        }
                                        break;
                        }
                        return TableItemLabeledValue.of(this[propertyName]);
                } else {
                        return TableItemLabeledValue.empty();
                }
        }
}
